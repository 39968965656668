import React, { useState, useCallback, forwardRef } from 'react';
import { TextField, Icon } from '@maxi-innovation/ui-kit-frontend';

interface IPasswordTextFieldProps {
	className?: string;
	id?: string;
	description?: string;
	name: string;
	isError?: boolean;
	label?: string;
	errorMessage?: string;
}

export const PasswordTextField = forwardRef<HTMLInputElement, IPasswordTextFieldProps>(
	({ className, id, name, description, errorMessage, isError, label }, ref) => {
		const [showPass, setShowPass] = useState(false);

		const toggleShowPass = useCallback((e: React.SyntheticEvent) => {
			e.preventDefault();
			e.stopPropagation();
			setShowPass(prev => !prev);
		}, []);

		return (
			<TextField
				variant="default"
				type={showPass ? 'text' : 'password'}
				ref={ref}
				className={className}
				label={label}
				name={name}
				id={id}
				description={description}
				error={isError}
				errorMessage={errorMessage}
				iconLeft={
					<Icon
						id="auth-control-password-show"
						iconName={showPass ? 'EyeOff' : 'Eye'}
						onClick={toggleShowPass}
						w={19}
						h={19}
						color={isError ? 'mc74' : 'sc77'}
					/>
				}
			/>
		);
	}
);
